import { Button, Flex, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { log } from '@cccom/shared/logging';
import { zCCString } from '@cccom/shared/utils/common';

import { DebouncedButton } from '../debounced-button/DebouncedButton';

type AddCommentProps = {
  onSubmit: (data: CommentData) => Promise<void>;
  maxLength?: number;
};

const commentSchema = z.object({
  message: zCCString({ field: 'Comment' }),
});

type CommentData = z.infer<typeof commentSchema>;

export function AddComment({ onSubmit, maxLength = 500 }: AddCommentProps) {
  const [t] = useTranslation();
  const [showActionButtons, setShowActionButtons] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = useForm<CommentData>({
    resolver: zodResolver(commentSchema),
    defaultValues: { message: '' },
  });

  const handleSubmitComment = async (data: CommentData) => {
    try {
      await onSubmit(data);
      reset();
      setShowActionButtons(false);
    } catch (error) {
      log.error('Failed to submit comment:', error);
    }
  };

  const cancelHandler = () => {
    reset();
    setShowActionButtons(false);
  };

  return (
    <Flex direction="column" gap="7" mb="6">
      <Textarea
        onFocus={() => setShowActionButtons(true)}
        id="comment"
        rows={3}
        maxLength={maxLength}
        resize="none"
        placeholder={t('common.actions.add_a_comment')}
        {...register('message')}
        data-cy="comment"
        _placeholder={{ color: 'gray.400' }}
      />

      {showActionButtons && (
        <Flex gap="2" alignSelf="start">
          <DebouncedButton
            size="sm"
            colorScheme="brand"
            variant="primary"
            onClick={handleSubmit(handleSubmitComment)}
            isDisabled={!isDirty || !isValid}
          >
            {t('common.actions.save_comment')}
          </DebouncedButton>
          <Button
            size="sm"
            colorScheme="brand"
            variant="outline"
            onClick={cancelHandler}
          >
            {t('common.actions.cancel')}
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
